import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import Moment from 'react-moment'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import theToken from '../Token';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';

export default function OvinoPesajesList () {
    
    const [ovino, setOvino] = useState('');
    const [pesosList, setsPesosList] = useState([]);
    const [peso, setPeso] = useState('');
    const ovinoId = useParams().ovinoId;
    const nombreOvino = useParams().nombreOvino;
    const estableId = useParams().estableId;
    const nombreEstable = useParams().nombreEstable;
    
    useEffect(() => {
        const getOvino = async () => {
            if (ovinoId) {
                const resOv = await axios.get('/api/ovinos/' + ovinoId, theToken());
                setOvino(resOv.data);
            }
        }
        const getPesosOvino = async () => {
            if (ovinoId) {
                const resPe = await axios.get('/api/pesos/ovino/'+ ovinoId, theToken())
                setsPesosList(resPe.data);
            }
        }
        getPesosOvino();
        getOvino();
    },[ovinoId]);

    useEffect(() => {
        const deletePeso = async () => {
            if (peso) {
                const deletePeso = {
                    activo: false
                };
                await axios.put('/api/pesos/delete/' + peso, deletePeso, theToken());            
            }
        }
        deletePeso();
    },[peso]);

    return (
        <> 
            <Logueado />
            <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div>
                <div className="col-md-10 p-10">
                </div>
                <div className="col-md-12 p-12">
                    <h2 className="textBlanco">Pesajes del</h2>
                    <h1 className="textBlanco">Ovino: { ovino.numCaravana }</h1>

                </div>
                <div className="col-md-10 p-10">
                </div> 
                <div className="col-md-2 p-2">
                    <Button variant="success" href={"/agregarPesaje/"  + estableId + "/" + nombreEstable + "/" + ovino._id + "/" + ovino.nombre}><b>Agregar pesaje</b></Button>
                </div>
                {
                    
                    pesosList.map(peso => (
                        <div className="col-md-3 p-2" key={peso._id}>
                            <div className="card">
                                <div className="card-body">
                                    <p>
                                        Peso: {peso.peso} Kilogramos
                                    </p>
                                    <p>
                                        Fecha de pesaje: <Moment format="DD/MM/YYYY">{peso.fechaPesaje}</Moment>
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <Link to={"/editarPeOv/" + estableId + "/" + nombreOvino + "/" + ovinoId + "/" + nombreOvino + "/" + peso._id} className="btn btn-primary">
                                        <i className="material-icons">
                                            border_color</i>
                                    </Link>
                                    <form /*onSubmit={this.onSubmit}*/>
                                        <button 
                                            className="btn btn-danger"
                                            onClick={() => {setPeso(peso._id);}}
                                            >
                                            <i className="material-icons">
                                            delete</i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    ))
                }                
            </div>
        </>
    );
}
