import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route } from 'react-router-dom'

//import NavigationLogin from './components/nav/NavigationLogin'
import Navigation2 from './components/nav/Navigation2'
import Home from './components/home/Home'

//Usuario
import Login from './components/login/Login'
import CerrarSesion from './helpers/CerrarSesion';
import CreateUsEs from './components/asignar/CreateUsEs'
import CreateUser from './components/create/CreateUser'

//Establecimiento
import EstablesList from './components/list/EstablesList'
import EstableOvinosList from './components/list/EstableOvinosList'
import CreateEstable from './components/create/CreateEstable'


//Ovino
import OvinosList from './components/list/OvinosList'
import CreateOvinoGeneral from './components/create/CreateOvinoGeneral'
import CreateOvino from './components/create/CreateOvino'
import MostrarOvino from './components/list/MostrarOvino'
import AsignarPartoCordero from './components/asignar/AsignarPartoCordero';

//Patologia
import OvinoPatologiasList from './components/list/OvinoPatologiasList'
import PatologiasList from './components/list/PatologiasList'
import CreatePatologiaOvino from './components/create/CreatePatologiaOvino'
import CreatePatologiaGeneral from './components/create/CreatePatologiaGeneral'
import AsignarPaOv from './components/asignar/AsignarPaOv'

//Tratamiento
import PatologiaTratamientosList from './components/list/PatologiaTratamientosList'
import CreateTratamientoPatologia from './components/create/CreateTratamientoPatologia'

//Sanidades
import EstableSanidadesList from './components/list/EstableSanidadesList'
import CreateSanidad from './components/create/CreateSanidad'
import AsignarOvinosSanidad from './components/asignar/AsignarOvinosSanidad'
import SanidadOvinosList from './components/list/SanidadOvinosList'

//Servicios
import EstableServiciosList from './components/list/EstableServiciosList'
import CreateServicio from './components/create/CreateServicio'
import AsignarCarneroServicio from './components/asignar/AsignarCarneroServicio'

//Gestacion
import ServicioGestacionesList from './components/list/ServicioGestacionesList'
import CreateGestacion from './components/create/CreateGestacion'
import AsignarOvejaGestacion from './components/asignar/AsignarOvejaGestacion';
// import AsignarServicioGestacion from './components/asignar/AsignarServicioGestacion'

//Parto
import GestacionPartoList from './components/list/GestacionPartoList';
import CreateParto from './components/create/CreateParto';

//Peso
import OvinoPesajesList from './components/list/OvinoPesajesList'
import CreatePesajeOvino from './components/create/CreatePesajeOvino'


import './App.css';

function App() {

  return (
  <Router>
    <Navigation2 />
      <div className="container p-4">
  
      <Route path="/" exact component={Home} />
      <Route path="/login" exact component={Login} />
      <Route path="/cerrarSesion" exact component={CerrarSesion} />
      <Route path="/user" component={CreateUser} />
      <Route path="/crearUsEs" component={CreateUsEs} />
      
      {/* Establecimiento */}
      <Route path="/establecimientos" exact component={EstablesList} />
      <Route path="/estable/ovinos/:estableId/:nombreEstable" component={EstableOvinosList} />
      <Route path="/crearEstablecimiento" component={CreateEstable} />
      <Route path="/editarEstable/:id" component={CreateEstable} />
    
      {/* Ovino */}
      <Route path="/ovinos" component={OvinosList} />
      <Route path="/editOvino/:id" component={CreateOvinoGeneral} />
      <Route path="/agregarOvEs/:estableId/:nombreEstable" component={CreateOvino} />
      <Route path="/editarOvEs/:estableId/:nombreEstable/:ovinoId" component={CreateOvino} />
      <Route path="/MostrarOvino/:estableId/:ovinoId" component={MostrarOvino} />
      <Route path="/agregarPartoCordero/:partoId" component={AsignarPartoCordero} />

      {/* Patología */}
      <Route path="/patologias" component={PatologiasList} />
      <Route path="/ovino/patologias/:estableId/:nombreEstable/:ovinoId/:nombreOvino" component={OvinoPatologiasList} />
      <Route path="/crearPaOv" component={AsignarPaOv} />
      <Route path="/agregarPatologia/:estableId/:nombreEstable/:ovinoId/:nombreOvino" component={CreatePatologiaOvino} />
      <Route path="/crearPatologia" component={CreatePatologiaGeneral} />
      <Route path="/editarPaOv/:estableId/:nombreEstable/:ovinoId/:nombreOvino/:patologiaId" component={CreatePatologiaOvino} />
      <Route path="/editPatologia/:id" component={CreatePatologiaGeneral} />

      {/* Tratamiento */}
      <Route path="/patologia/tratamientos/:estableId/:nombreEstable/:ovinoId/:nombreOvino/:patologiaId" component={PatologiaTratamientosList} />
      <Route path="/agregarTratamiento/:estableId/:nombreEstable/:ovinoId/:nombreOvino/:patologiaId" component={CreateTratamientoPatologia} />
      <Route path="/editarTraOv/:estableId/:nombreEstable/:ovinoId/:nombreOvino/:patologiaId/:tratamientoId" component={CreateTratamientoPatologia} />
      
      {/* Sanidad */}
      <Route path="/estable/sanidades/:estableId/:nombreEstable" component={EstableSanidadesList} />
      <Route path="/sanidad/ovinos/:estableId/:sanidadId" component={SanidadOvinosList} />
      <Route path="/agregarSanidad/:estableId/:nombreEstable" component={CreateSanidad} />
      <Route path="/editarSaOv/:estableId/:nombreEstable/:sanidadId" component={CreateSanidad} />
      <Route path="/asignarOvinosSanidad/:estableId/:nombreEstable/:sanidadId" component={AsignarOvinosSanidad} />

      {/* Servicio */}
      <Route path="/estable/servicios/:estableId/:nombreEstable" component={EstableServiciosList} />
      <Route path="/agregarServicio/:estableId/:nombreEstable" component={CreateServicio} />
      <Route path="/editarSeOv/:estableId/:nombreEstable/:servicioId" component={CreateServicio} />
      <Route path="/asignarCarneroServicio/:estableId/:nombreEstable/:servicioId" component={AsignarCarneroServicio} />

      {/* Gestacion */}
      <Route path="/servicio/gestaciones/:servicioId" component={ServicioGestacionesList} />
      <Route path="/agregarGestacion/:servicioId/" component={CreateGestacion} />
      <Route path="/editarPrSe/:servicioId/:gestacionId" component={CreateGestacion} />
      <Route path="/asignarOvejaGestacion/:servicioId/:gestacionId" component={AsignarOvejaGestacion} />

      {/* Parto */}
      <Route path="/gestacion/parto/:gestacionId" component={GestacionPartoList} />
      <Route path="/agregarParto/:gestacionId" component={CreateParto} />
      <Route path="/editarPaPr/:gestacionId/:partoId" component={CreateParto} />
      <Route path="/asignarPartoCordero/:gestacionId/:partoId" component={AsignarPartoCordero} />

      {/* Pesaje */}
      <Route path="/ovino/pesajes/:estableId/:nombreEstable/:ovinoId/:nombreOvino" component={OvinoPesajesList} />
      <Route path="/agregarPesaje/:estableId/:nombreEstable/:ovinoId/:nombreOvino" component={CreatePesajeOvino} />
      <Route path="/editarPeOv/:estableId/:nombreEstable/:ovinoId/:nombreOvino/:pesajeId" component={CreatePesajeOvino} />

    </div>
  </Router>
    );
}

export default App;