import Cookies from 'js-cookie';

function theToken() {
    let token = null;
    const loggedUserJSON = Cookies.get('token');
    if (loggedUserJSON) {
        token = loggedUserJSON;
    };
    const config = {
        headers: {
            "Authorization": token
        }
    };

    return config;
}    


export default theToken;