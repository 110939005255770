import React, { Component } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import Moment from 'react-moment'
import theToken from '../Token';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';

export default class AsignarOvEs extends Component {
    state = {
        ovino: '',
        ovinosData: [],
        ovinos: [],
        ovinosSan: [],
        sanidad: '',
        nombreOvino: '',
        estableId: '',
        nombreEstable: '',
        editing: false,
        _idOvino: '',
        _idSanidad: ''
    }

    async componentDidMount() {
        const estableId = this.props.match.params.estableId;
        const nombreEstable = this.props.match.params.nombreEstable;
        const sanidadId = this.props.match.params.sanidadId;
        const resOv = await axios.get('/api/ovinos/sinagregarasanidad/' + estableId + '/' + sanidadId, theToken());

        if (resOv.data.length > 0) {
            this.setState({
                estableId: estableId,
                nombreEstable: nombreEstable,
                sanidad: sanidadId,
                ovinosData: resOv.data,
                ovinos: resOv.data.map(ovino => [ovino._id, ovino.nombre]),
            })
        }        
    }

    onSubmit = async (e) => {
        e.preventDefault();
        if (this.state.sanidad && this.state.sanidad) {
            const addOvinoSanidad = {
                ovino: this.state.ovino,
            };
            await axios.put('/api/sanidades/addOvinoSanidad/' + this.state.sanidad, addOvinoSanidad, theToken());
        }
        if (this.state.ovino && this.state.ovino) {
            const addSanidadOvino = {
                sanidad: this.state.sanidad,
            };
            await axios.put('/api/ovinos/addSanidadOvino/' + this.state.ovino, addSanidadOvino, theToken());
            window.location.href = '/asignarOvinosSanidad/' + this.state.estableId + '/' + this.state.nombreEstable + "/" + this.state.sanidad;
        }

    }

    onInputChange = (e) => {
        console.log(e.target.name);
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeDate = date => {
        this.setState({ date });
    }

    render() {
        return (
            <div>
                <Logueado />
                <div className="row">
                    <div className="col-md-2 p-2">
                        <Volver />
                    </div>
                    <div className="col-md-10 p-10">
                    </div> 
                    <div className="col-md-12 p-12">
                        <h1 className="textBlanco">Ingresar ovinos en esta sanidad</h1>
                    </div>
                    <div className="col-md-11 p-11">
                    </div> 
                        {
                            this.state.ovinosData.map(ovino => {
                                return <div className="col-md-3 p-2" key={ovino._id}>
                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between">
                                            <h5>Nombre: {ovino.nombre}</h5>
                                        </div>
                                        <div className="card-body">
                                            <p>
                                                Número de caravana: {ovino.numCaravana}
                                            </p>
                                            <p>
                                                Color de caravana: {ovino.colorCaravana}
                                            </p>
                                            <p>
                                                Tatuaje: {ovino.tatuaje}
                                            </p>
                                            <p>
                                                sexo: {ovino.sexo}
                                            </p>
                                            <p>
                                                raza: {ovino.raza}
                                            </p>
                                            <p>
                                                Fecha de nacimiento: <Moment format="DD/MM/YYYY">{ovino.nacimiento}</Moment>
                                            </p>
                                            <p>
                                                Apto para Reproducción: {ovino.aptoReprodruccion}
                                            </p>
                                        </div>
                                        <div className="card-footer d-flex justify-content-between">
                                            <form onSubmit={this.onSubmit}>
                                                <button 
                                                    className="btn btn-primary"
                                                    onClick={() => this.setState({ ovino: ovino._id })}
                                                    >
                                                    Colocar
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            })
                        }                
                </div>
            </div>
        )
    }
}
