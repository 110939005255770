import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import DatePicker, {registerLocale} from 'react-datepicker';
import Moment from 'react-moment';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import theToken from '../Token';
import es from 'date-fns/locale/es';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';
registerLocale('es', es)

export default class MostrarOvino extends Component {
    state = {
        nombre: '',
        numCaravana: '',
        colorCaravana: '',
        sexo: '',
        raza: '',
        tatuaje: '',
        nacimiento: '',
        aptoReproduccion: '',
        nacio: '',
        _id: '',
        establecimiento: '',
        nombreEstable: '',
    }

    async componentDidMount() {
        const estableId = this.props.match.params.estableId;
        const nombreEstable = this.props.match.params.nombreEstable;

        if (estableId) {
            this.setState({
                establecimiento: estableId,
                nombreEstable: nombreEstable
            })
        }
        if (estableId) {
            const res = await axios.get('/api/ovinos/' + this.props.match.params.ovinoId, theToken());
            this.setState({
                nombre: res.data.nombre,
                numCaravana: res.data.numCaravana,
                colorCaravana: res.data.colorCaravana,
                sexoSelected: res.data.sexo,
                raza: res.data.raza,
                cruzamientoSelected: res.data.cruzamiento,
                tatuaje: res.data.tatuaje,
                nacimiento: new Date(res.data.nacimiento),
                aptoReproduccionSelected: res.data.aptoReproduccion,
                estableSelected: res.data.establecimiento,
                nacioSelected: res.data.nacio,
                _id: res.data._id,
                editing: true
            });
        }
    }

    render() {
        return (
            <>
            <Logueado />
            <div className="row">
                    <div className="col-md-2 p-2">
                        <Volver />
                    </div> 
                    <div className="col-md-10 p-10">
                    </div>
                    <div className="col-md-12 p-12">
                        <h2 className="textBlanco">Ovino</h2>
                    </div>
                    <div className="col-md-3 p-2" key={this.state._id}>
                        <div className="card">
                            <div className="card-body">
                                <h5>Número de caravana: {this.state.numCaravana}</h5>
                                <p>
                                    Nombre: {this.state.nombre}
                                </p>
                                <p>
                                    Color de caravana: {this.state.colorCaravana}
                                </p>
                                <p>
                                    Tatuaje: {this.state.tatuaje}
                                </p>
                                <p>
                                    sexo: {this.state.sexo}
                                </p>
                                <p>
                                    raza: {this.state.raza}
                                </p>
                                <p>
                                    Fecha de nacimiento: <Moment format="DD/MM/YYYY">{this.state.nacimiento}</Moment>
                                </p>
                                <p>
                                    Tatuaje: {this.state.aptoReprodruccion}
                                </p>
                            </div>    
                            <div className="card-footer d-flex justify-content-between">
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        Opciones
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href={"/ovino/patologias/" + this.state.establecimiento + "/" + this.state.nombreEstable + "/" + this.state._id + "/" + this.state.nombre}>Patologías</Dropdown.Item>
                                        <Dropdown.Item href={"/ovino/pesajes/" + this.state.establecimiento + "/" + this.state.nombreEstable + "/" + this.state._id + "/" + this.state.nombre}>Pesajes</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Link to={"/editarOvEs/" + this.state.establecimiento + "/" + this.state.nombreEstable + "/" + this.state._id} className="btn btn-primary">
                                    <i className="material-icons">
                                        border_color</i>
                                </Link>
                            </div>
                        </div>
                    </div>                     
                </div>
            </>
        );
    }
}
