import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Moment from 'react-moment'
import axios from 'axios';
import theToken from '../Token';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';

export default function SanidadOvinosList () {
    
    const [ovinosList, setsOvinosList] = useState([]);
    const [ovino, setOvino] = useState('');
    const [sanidad, setSanidad] = useState('');
    const estableId = useParams().estableId;
    const sanidadId = useParams().sanidadId;

    useEffect(() => {
        const getOvinosSanidad = async () => {
            if (sanidadId) {
                const resOv = await axios.get('/api/ovinos/ensanidad/'+ estableId + '/' + sanidadId, theToken())
                setsOvinosList(resOv.data);
            }
        }
        getOvinosSanidad();
    },[sanidadId]);

    useEffect(() => {
        const deleteOvinoSanidad = async () => {
            if (sanidad) {
                console.log("delete");
                const deleteOvinoSanidad = {
                    activo: false,
                };
                await axios.put('/api/sanidades/deleteOvinoSanidad/' + sanidad + "/" + ovino, deleteOvinoSanidad, theToken());
                console.log("delete");
            }
        }
        deleteOvinoSanidad();
    },[ovino]);
    
    
    useEffect(() => {
        const deleteSanidadOvino = async () => {
            if (ovino) {
                const deleteSanidadOvino = {
                    activo: false,
                };
                await axios.put('/api/ovinos/deleteSanidadOvino/' + ovino + "/" + sanidad, deleteSanidadOvino, theToken());
                console.log("delete");
            }
        }
        deleteSanidadOvino();
    },[sanidad]);

    return (
        <>  
            <Logueado />
            <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div> 
                <div className="col-md-10 p-10">
                </div>
                <div className="col-md-12 p-12">
                    <h2 className="textBlanco">Ovinos en</h2>
                    <h1 className="textBlanco">Sanidad</h1>
                </div>
                {
                    
                    ovinosList.map(ovino => (
                        <div className="col-md-3 p-2" key={ovino._id}>
                            <div className="card">
                                <div className="card-body">
                                    <p>
                                        Nombre: {ovino.nombre}
                                    </p>
                                    <p>
                                        Número de caravana: {ovino.numCaravana}
                                    </p>
                                    <p>
                                        Color de caravana: {ovino.colorCaravana}
                                    </p>
                                    <p>
                                        Tatuaje: {ovino.tatuaje}
                                    </p>
                                    <p>
                                        sexo: {ovino.sexo}
                                    </p>
                                    <p>
                                        raza: {ovino.raza}
                                    </p>
                                    <p>
                                        Fecha de nacimiento: <Moment format="DD/MM/YYYY">{ovino.nacimiento}</Moment>
                                    </p>
                                    <p>
                                        Tatuaje: {ovino.aptoReprodruccion}
                                    </p>
                                </div>  
                                <div className="card-footer d-flex justify-content-between">
                                    <form /*onSubmit={this.onSubmit}*/>
                                        <button 
                                            className="btn btn-danger"
                                            onClick={() => {setOvino(ovino._id); setSanidad(sanidadId);}}
                                            >
                                            <b>Sacar</b>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    ))
                }                     
            </div>
        </>
    );
}
