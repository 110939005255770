import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoginButtonHome from '../login/LoginButtonHome';
import theToken from '../Token';


export default class Home extends Component {

    render() {
        let login;
        const token = theToken().headers.Authorization;
        if(token === null || token === "null"){
            login = (
                <div className="homeContenedor">
                <Container>
                    <Row>
                    <Col xs={12}><LoginButtonHome />
                    </Col>
                    </Row>
                </Container> 
            </div>
            ); 
        } else {
           login = window.location.href = '/establecimientos'
        }
        return (
          <>
            {login}
          </>  
        );
    }
}