import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

export function Volver() {
  const history = useHistory();

  function handleClick() {
    history.goBack();
  }

  return (
    <Button variant="warning" onClick={handleClick}><b>Volver</b></Button>
  );
}