import React, { Component } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
//import Button from 'react-bootstrap/Button';
import Moment from 'react-moment'
import theToken from '../Token';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';

export default class AsignarOvejaGestacion extends Component {
    state = {
        oveja: '',
        ovejasData: [],
        ovejas: [],
        gestacion: '',
        nombreOveja: '',
        editing: false,
        _idOveja: '',
        _idGestacion: ''
    }

    async componentDidMount() {
        const gestacionId = this.props.match.params.gestacionId;
        const servicioId = this.props.match.params.servicioId;
        const resSe = await axios.get('/api/servicios/' + servicioId, theToken());
        const resOv = await axios.get('/api/ovinos/hembras/ovejas/' + resSe.data.establecimiento, theToken());
        if (resOv.data.length > 0) {
            this.setState({
                gestacion: gestacionId,
                servicioId: servicioId,
                estableId: resSe.data.establecimiento,
                ovejasData: resOv.data,
                ovejas: resOv.data.map(oveja => [oveja._id, oveja.nombre]),
            })
        }        
    }

    onSubmit = async (e) => {
        e.preventDefault();
        if (this.state.gestacion && this.state.gestacion) {
            const addOvejaGestacion = {
                oveja: this.state.oveja
            };
            await axios.put('/api/gestaciones/addOvejaGestacion/' + this.state.gestacion, addOvejaGestacion, theToken());
            window.location.href = '/servicio/gestaciones/' + this.state.servicioId;
        }
    }

    onInputChange = (e) => {
        console.log(e.target.name);
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeDate = date => {
        this.setState({ date });
    }

    render() {
        return (
            <div>
                <Logueado />
                <div className="row">
                    <div className="col-md-2 p-2">
                        <Volver />
                    </div>
                    <div className="col-md-10 p-10">
                    </div> 
                    <div className="col-md-12 p-12">
                        <h1 className="textBlanco">Ingresar Oveja en esta Gestación</h1>
                    </div>
                    <div className="col-md-11 p-11">
                    </div> 
                    {
                        this.state.ovejasData.map(oveja => {
                            return <div className="col-md-3 p-2" key={oveja._id}>
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between">
                                        <h5>Nombre: {oveja.nombre}</h5>
                                    </div>
                                    <div className="card-body">
                                        <p>
                                            Número de caravana: {oveja.numCaravana}
                                        </p>
                                        <p>
                                            Color de caravana: {oveja.colorCaravana}
                                        </p>
                                        <p>
                                            Tatuaje: {oveja.tatuaje}
                                        </p>
                                        <p>
                                            raza: {oveja.raza}
                                        </p>
                                        <p>
                                            sexo: {oveja.sexo}
                                        </p>
                                        <p>
                                            Fecha de nacimiento: <Moment format="DD/MM/YYYY">{oveja.nacimiento}</Moment>
                                        </p>
                                        <p>
                                            Apto para Reproducción: {oveja.aptoReprodruccion}
                                        </p>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between">
                                        <form onSubmit={this.onSubmit}>
                                            <button 
                                                className="btn btn-primary"
                                                onClick={() => this.setState({ oveja: oveja._id })}
                                            >
                                                Colocar
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        })
                    }                
                </div>
            </div>
        )
    }
}
