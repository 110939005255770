import React, { Component } from 'react'
import DatePicker, {registerLocale} from 'react-datepicker';
import Button from 'react-bootstrap/Button';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import theToken from '../Token';
import es from 'date-fns/locale/es';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';
registerLocale('es', es)

export default class CreatePatologiaOvino extends Component {
    state = {
        nomPatologia: '',
        fechaDiagn: new Date(),
        tipoPatologia: '',
        descripDiagn: '',
        token: '',
        editing: false,
        _id: '',
        ovino: '',
    }

    async componentDidMount() {
        const ovinoId = this.props.match.params.ovinoId;
        const nombreOvino = this.props.match.params.nombreOvino;
        const estableId = this.props.match.params.estableId;
        const nombreEstable = this.props.match.params.nombreEstable;
        if (ovinoId) {
            this.setState({
                ovino: ovinoId,
                nombreOvino: nombreOvino,
                estableId: estableId,
                nombreEstable: nombreEstable,
            })
        }
        if (ovinoId) {
            const res = await axios.get('/api/patologias/' + this.props.match.params.patologiaId, theToken());
            this.setState({
                nomPatologia: res.data.nomPatologia,
                fechaDiagn: new Date(res.data.fechaDiagn),
                tipoPatologia: res.data.tipoPatologia,
                descripDiagn: res.data.descripDiagn,
                ovino: res.data.ovino,
                _id: res.data._id,
                editing: true
            });
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();
        if (this.state.editing) {
            const updatedPatologia = {
                nomPatologia: this.state.nomPatologia,
                fechaDiagn: this.state.fechaDiagn,
                tipoPatologia: this.state.tipoPatologia,
                descripDiagn: this.state.descripDiagn,
                ovino: this.state.ovino,
            };
            await axios.put('/api/patologias/' + this.state._id, updatedPatologia, theToken());
        } else {
            const newPatologia = {
                nomPatologia: this.state.nomPatologia,
                fechaDiagn: this.state.fechaDiagn,
                tipoPatologia: this.state.tipoPatologia,
                descripDiagn: this.state.descripDiagn,
                ovino: this.state.ovino,
                token: theToken()
            };
            await axios.post('/api/patologias', newPatologia, theToken());
        }
        window.location.href = '/ovino/patologias/' + this.state.estableId + '/' + this.state.nombreEstable + "/" + this.state.ovino + '/' + this.state.nombreOvino;
    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeDate = fechaDiagn => {
        this.setState({ fechaDiagn });
    }

    render() {
        return (
        <>  
            <Logueado />
            <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div>
                <div className="col-md-10 p-10">
                </div> 
                <div className="col-md-6 offset-md-3">
                    <h2 className="textBlanco">Agregar Patología en el</h2>
                    <h1 className="textBlanco">Ovino: { this.state.nombreOvino }</h1>
                    <div className="card card-body">
                        <form onSubmit={this.onSubmit}>
                            {/* Nombre patología*/}
                            <div className="form-group">
                                <h6>Nombre de patología</h6>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nombre de patología"
                                    onChange={this.onInputChange}
                                    name="nomPatologia"
                                    value={this.state.nomPatologia}
                                />
                            </div>
                            <br></br>
                            {/* Tipo de patología */}
                            <div className="form-group">
                                <h6>Tipo</h6>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Tipo de patología"
                                    onChange={this.onInputChange}
                                    name="tipoPatologia"
                                    value={this.state.tipoPatologia}
                                required/>
                            </div>
                            <br></br>
                            {/* Descripción del diagnóstico */}
                            <div className="form-group">
                                <h6>Descripción de diagnóstico</h6>
                                <textarea
                                    className="form-control"
                                    placeholder="Descripción del diagnóstico"
                                    onChange={this.onInputChange}
                                    name="descripDiagn"
                                    value={this.state.descripDiagn}
                                    rows={4}
                                    cols={40}
                                />
                            </div>
                            <br></br>
                            <h6>Fecha de diagnóstico</h6>
                            {/* Fecha de diagnóstico */}
                            <div className="form-group">
                                <DatePicker selected={this.state.fechaDiagn} onChange={this.onChangeDate} locale="es" className="form-control pickers" dateFormat="dd/MM/yyyy"/>
                            </div>
                            <button className="btn btn-primary">
                                Guardar <i className="material-icons">
                                    assignment
                                    </i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
