import { useState, useEffect } from 'react';
import theToken from "../components/Token";
import axios from 'axios'

async function cargarOvino(ovinoId) {
    const res = await axios.get('/api/ovinos/' + ovinoId, theToken())
    return res.data;
}

export function GetOvinoNumCaravana({ovinoId}) {
  const [data, setData] = useState(null);
  if(ovinoId !== undefined){
    ovinoId = ovinoId.toString();
  }
  useEffect(() => {
    cargarOvino(ovinoId).then((res) => setData(res));
  }, []);

  if (!data) {
    return (`Agregue un ovino`);
  }

  return (
    `${data.numCaravana}`
  );
}
