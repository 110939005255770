import React, { Component } from 'react'
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import theToken from '../Token';
import es from 'date-fns/locale/es';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';
registerLocale('es', es)

export default class CreateServicio extends Component {
    state = {
        fechaServicio: new Date(),
        tipoServicioSelected: 'Vía intrauterina',
        tiposServicio: ['Vía intrauterina', 'Vía cervical', 'Monta corral', 'Monta campo'],
        tipoServicio: '', 
        establecimiento: '',
        editing: false,
        _id: ''
    }

    async componentDidMount() {
        const servicioId = this.props.match.params.servicioId;
        const estableId = this.props.match.params.estableId;
        const nombreEstable = this.props.match.params.nombreEstable;
        
        if (estableId) {
            this.setState({
                establecimiento: estableId,
                nombreEstable: nombreEstable
            })
        }
        if (servicioId) {
            const res = await axios.get('/api/servicios/' + this.props.match.params.servicioId, theToken());
            this.setState({
                fechaServicio: new Date(res.data.fechaServicio),
                tipoServicioSelected: res.data.tipoServicio,
                tipoServicio: res.data.tipoServicio,
                establecimiento: res.data.establecimiento,
                _id: res.data._id,
                editing: true
            });
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();
        if (this.state.editing) {
            const updatedServicio = {
                fechaServicio: this.state.fechaServicio,
                tipoServicio: this.state.tipoServicioSelected,
                establecimiento: this.state.establecimiento
            };
            await axios.put('/api/servicios/' + this.state._id, updatedServicio, theToken());
        } else {
            const newServicio = {
                tipoServicio: this.state.tipoServicioSelected,
                fechaServicio: this.state.fechaServicio,
                establecimiento: this.state.establecimiento,
                token: theToken()
            };
            await axios.post('/api/servicios', newServicio, theToken());
        }
        window.location.href = '/estable/servicios/' + this.state.establecimiento + '/' + this.state.nombreEstable;


    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeDate = fechaServicio => {
        this.setState({ fechaServicio });
    }

    render() {
        return (
        <>  
            <Logueado />
            <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div>
                <div className="col-md-10 p-10">
                </div> 
                <div className="col-md-6 offset-md-3">
                    <div className="card card-body">
                        <h4>Agregar Servicio en establecimiento "{this.state.nombreEstable}"</h4>
                        <form onSubmit={this.onSubmit}>
                            {/* Tipo de servicio */}
                            <div className="form-group">
                                <br></br>
                                <h6>Tipo de la servicio</h6>
                                <select
                                    className="form-control"
                                    value={this.state.tipoServicioSelected}
                                    onChange={this.onInputChange}
                                    name="tipoServicioSelected"
                                    required>
                                    {
                                        this.state.tiposServicio.map(tipoServicio => (
                                            <option key={tipoServicio} value={tipoServicio}>
                                                {tipoServicio}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <br></br>
                            {/* Fecha de la servicio */}
                            <h6>Fecha de la servicio</h6>
                            <div className="form-group">
                                <DatePicker selected={this.state.fechaServicio} onChange={this.onChangeDate} locale="es" className="form-control pickers" dateFormat="dd/MM/yyyy"/>
                            </div>
                            <button className="btn btn-primary">
                                Guardar <i className="material-icons">
                                    assignment
                                    </i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
