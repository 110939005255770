import React, { Component } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
//import Button from 'react-bootstrap/Button';
import Moment from 'react-moment'
import theToken from '../Token';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';

export default class AsignarCarneroServicio extends Component {
    state = {
        carnero: '',
        carnerosData: [],
        carneros: [],
        servicio: '',
        nombreCarnero: '',
        editing: false,
        _idCarnero: '',
        _idServicio: ''
    }

    async componentDidMount() {
        const estableId = this.props.match.params.estableId;
        const nombreEstable = this.props.match.params.nombreEstable;
        const servicioId = this.props.match.params.servicioId;
        const resSe = await axios.get('/api/servicios/' + servicioId, theToken());
        const resCa = await axios.get('/api/ovinos/machos/carneros/' + resSe.data.establecimiento, theToken());
        if (resCa.data.length > 0) {
            this.setState({
                estableId: estableId,
                nombreEstable: nombreEstable,
                servicio: servicioId,
                carnerosData: resCa.data,
                carneros: resCa.data.map(carnero => [carnero._id, carnero.nombre]),
            })
        }        
    }

    onSubmit = async (e) => {
        e.preventDefault();
        console.log(this.state.servicio);
        if (this.state.servicio && this.state.servicio) {
            console.log(this.state.servicio);
            const addCarneroServicio = {
                carnero: this.state.carnero
            };
            await axios.put('/api/servicios/addCarneroServicio/' + this.state.servicio, addCarneroServicio, theToken());
            window.location.href = '/estable/servicios/' + this.state.estableId + '/' + this.state.nombreEstable + "/" + this.state.servicio;
        }
    }

    onInputChange = (e) => {
        console.log(e.target.name);
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeDate = date => {
        this.setState({ date });
    }

    render() {
        return (
            <div>
                <Logueado />
                <div className="row">
                    <div className="col-md-2 p-2">
                        <Volver />
                    </div>
                    <div className="col-md-10 p-10">
                    </div> 
                    <div className="col-md-12 p-12">
                        <h1 className="textBlanco">Ingresar carnero en esta servicio</h1>
                    </div>
                    <div className="col-md-11 p-11">
                    </div> 
                    {
                        this.state.carnerosData.map(carnero => {
                            return <div className="col-md-3 p-2" key={carnero._id}>
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between">
                                        <h5>Nombre: {carnero.nombre}</h5>
                                    </div>
                                    <div className="card-body">
                                        <p>
                                            Número de caravana: {carnero.numCaravana}
                                        </p>
                                        <p>
                                            Color de caravana: {carnero.colorCaravana}
                                        </p>
                                        <p>
                                            Tatuaje: {carnero.tatuaje}
                                        </p>
                                        <p>
                                            raza: {carnero.raza}
                                        </p>
                                        <p>
                                            sexo: {carnero.sexo}
                                        </p>
                                        <p>
                                            Fecha de nacimiento: <Moment format="DD/MM/YYYY">{carnero.nacimiento}</Moment>
                                        </p>
                                        <p>
                                            Apto para Reproducción: {carnero.aptoReprodruccion}
                                        </p>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between">
                                        <form onSubmit={this.onSubmit}>
                                            <button 
                                                className="btn btn-primary"
                                                onClick={() => this.setState({ carnero: carnero._id })}
                                            >
                                                Colocar
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        })
                    }                
                </div>
            </div>
        )
    }
}
