import React, { Component } from 'react'
import {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import theToken from '../Token';
import es from 'date-fns/locale/es';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';
registerLocale('es', es)

export default class CreateGestacion extends Component {
    state = {
        prenadaSelected: 'No',
        preguntaPrenada: ['Si', 'No'],
        diagnGestacion: '',
        prenada: '',
        servicio: '',
        editing: false,
        _id: ''
    }

    async componentDidMount() {
        const gestacionId = this.props.match.params.gestacionId;
        const servicioId = this.props.match.params.servicioId;
        
        if (servicioId) {
            const resSe = await axios.get('/api/servicios/' + servicioId, theToken());
            this.setState({
                servicio: servicioId,
                establecimiento: resSe.data.establecimiento,
            })
        }
        if (gestacionId) {
            const res = await axios.get('/api/gestaciones/' + gestacionId, theToken());
            this.setState({
                diagnGestacion: res.data.diagnGestacion,
                prenadaSelected: res.data.prenada,
                servicio: res.data.servicio,
                establecimiento: res.data.establecimiento,
                _id: res.data._id,
                editing: true
            });
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();
        if (this.state.editing) {
            const updatedGestacion = {
                diagnGestacion: this.state.diagnGestacion,
                prenada: this.state.prenadaSelected,
                servicio: this.state.servicio,
                establecimiento: this.state.establecimiento
            };
            await axios.put('/api/gestaciones/' + this.state._id, updatedGestacion, theToken());
        } else {
            const newGestacion = {
                diagnGestacion: this.state.diagnGestacion,
                prenada: this.state.prenadaSelected,
                servicio: this.state.servicio,
                establecimiento: this.state.establecimiento,
                token: theToken()
            };
            await axios.post('/api/gestaciones', newGestacion, theToken());
        }
        window.location.href = '/servicio/gestaciones/' + this.state.servicio;

    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        return (
        <>
            <Logueado />
            <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div>
                <div className="col-md-10 p-10">
                </div> 
                <div className="col-md-6 offset-md-3">
                    <div className="card card-body">
                        <h4>Agregar Gestación en Servicio</h4>
                        <form onSubmit={this.onSubmit}>
                            {/* Diagnostico de peeñez */}
                            <div className="form-group">
                                <br></br>
                                <h6>Diagnostico de gestación</h6>
                                <textarea
                                    className="form-control"
                                    placeholder="Diagnostico de gestación"
                                    onChange={this.onInputChange}
                                    name="diagnGestacion"
                                    value={this.state.diagnGestacion}
                                    rows={4}
                                    cols={40}
                                />
                            </div>
                            <br></br>
                            <h6>¿Quedó preñada?</h6>
                            <select
                                className="form-control"
                                value={this.state.prenadaSelected}
                                onChange={this.onInputChange}
                                name="prenadaSelected"
                                required>
                                {
                                    this.state.preguntaPrenada.map(prenada => (
                                        <option key={prenada} value={prenada}>
                                            {prenada}
                                        </option>
                                    ))
                                }
                            </select>
                            <button className="btn btn-primary">
                                Guardar <i className="material-icons">
                                    assignment
                                    </i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
