import React from 'react'
import theToken from '../components/Token';

export const Logueado = () => {
    const token = theToken().headers.Authorization;
    if (token === null || token ==="null") {
        window.location.href = '/'
    }
    return(
        <>
        </>
    )
}
