import React from 'react'
import theToken from '../components/Token';

function CerrarSesion() {
    const cerrar = async () => {
        document.cookie = `token=${null}; path=/; secure; samesite=strict`;
        window.location.href = '/'
    }
    const token = theToken().headers.Authorization;
    let logueado = false;
    if (token !== "null"){
        if(token !== null) {
            logueado = true;
        } 
    }

    return (
        <>
            {logueado === true && (
                <button onClick={cerrar}>Cerrar sesión</button>
            )}
        </>
    );
}

export default CerrarSesion;

