import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Moment from 'react-moment';
import theToken from '../Token';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';
import { GetOvinoNumCaravana } from '../../helpers/GetOvinoNumCaravana';


export default function GestacionPartoList () {
    const [corderosList, setsCorderosList] = useState([]);
    const [cordero, setCordero] = useState('');
    const [gestacion, setGestacion] = useState('');
    const [parto, setParto] = useState('');
    const [partoId, setPartoId] = useState('');
    const gestacionId = useParams().gestacionId;
    
    useEffect(() => {
        const getPartoGestacion = async () => {
            if (gestacionId) {
                const resPa = await axios.get('/api/partos/gestacion/'+ gestacionId, theToken())
                setParto(resPa.data);
            }
        }
        const getGestacion = async () => {
            if (gestacionId) {
                const resGe = await axios.get('/api/gestaciones/' + gestacionId, theToken());
                setGestacion(resGe.data);
            }
        }
        getPartoGestacion();
        getGestacion();
    },[]);
    
    useEffect(() => {
        if(parto.establecimiento !== undefined){
            const getCorderosParto = async () => {
                const resCo = await axios.get('/api/ovinos/enparto/'+ parto.establecimiento + '/' + parto._id, theToken())
                setsCorderosList(resCo.data);
            }
            getCorderosParto();
        }
    },[parto]);

    useEffect(() => {
        const deleteCordero = async () => {
            if (cordero) {
                const deleteCordero = {
                    activo: false
                };
                await axios.put('/api/ovinos/deleteCorderoParto/' + cordero, deleteCordero, theToken());            
            }
        }
        deleteCordero();
    },[cordero]);
    useEffect(() => {
        const deleteParto = async () => {
            if (parto) {
                const deleteParto = {
                    activo: false
                };
                await axios.put('/api/partos/delete/' + partoId, deleteParto, theToken());
                await redireccionar();
            }
        }
        deleteParto();
    },[partoId]);
    const redireccionar = () =>{
        window.location.href = '/servicio/gestaciones/' + gestacion.servicio;          
    }
    if(parto){
        return (
            <> 
                <Logueado />
                <div className="row">
                    <div className="col-md-2 p-2">
                        <Volver />
                    </div>
                    <div className="col-md-10 p-10">
                    </div>
    
                        <div className="col-md-12 p-12">
                            <h1 className="textBlanco">Parto</h1>
                        </div>
                        <div className="col-md-3 p-2" key={parto._id}>
                            <div className="card">
                                <div className="card-body">
                                    <h6>Tipo de parto:</h6>
                                    <p>
                                        {parto.tipoParto}
                                    </p>
                                    <h6>Observación:</h6>
                                    <p style={{ whiteSpace: 'pre-wrap' }}>
                                        {parto.observacion}
                                    </p>
                                    <h6>Fecha del parto:</h6>
                                    <p>
                                        <Moment format="DD/MM/YYYY">{parto.fechaParto}</Moment>
                                    </p>
                                </div>
                                
                                <div className="card-footer d-flex justify-content-between">
                                    <Button variant="success" href={"/asignarPartoCordero/" + gestacion._id + "/" + parto._id }><b>Agregar cría</b></Button>
                                    <Link to={"/editarPaPr/" + gestacion._id + "/" + parto._id} className="btn btn-primary">
                                        <i className="material-icons">
                                            border_color</i>
                                    </Link>
                                    <form /*onSubmit={this.onSubmit}*/>
                                        <button 
                                            className="btn btn-danger"
                                            onClick={() => {setPartoId(parto._id);}}
                                            >
                                            <i className="material-icons">
                                            delete</i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 p-2">
                        </div>
                        <div className="col-md-3 p-2">
                        </div>
                        <div className="col-md-3 p-2">
                        </div>
                        <hr></hr>
                        <hr></hr>
                        <h2 className="textBlanco" >Corderos nacidos</h2>
                        {
                            corderosList.map(cordero => { 
                                if(cordero.parto !== undefined){
                                    return <div className="col-md-3 p-2" key={cordero._id}>
                                        <div className="card">
                                            <div className="card-body">
                                                <h5>Número de caravana: {cordero.numCaravana}</h5>
                                                <p>
                                                    Nombre: {cordero.nombre}
                                                </p>
                                                <p>
                                                    Color de caravana: {cordero.colorCaravana}
                                                </p>
                                                <p>
                                                    Tatuaje: {cordero.tatuaje}
                                                </p>
                                                <p>
                                                    sexo: {cordero.sexo}
                                                </p>
                                                <p>
                                                    raza: {cordero.raza}
                                                </p>
                                                <p>
                                                    Fecha de nacimiento: <Moment format="DD/MM/YYYY">{cordero.nacimiento}</Moment>
                                                </p>
                                                <p>
                                                    Apto para Reproducción: {cordero.aptoReprodruccion}
                                                </p>
                                            </div>
                                            <div className="card-footer d-flex justify-content-between">
                                            <form /*onSubmit={this.onSubmit}*/>
                                                <button 
                                                    className="btn btn-danger"
                                                    onClick={() => {setCordero(cordero._id);}}
                                                    >
                                                    <i className="material-icons">
                                                    delete</i>
                                                </button>
                                            </form>
                                            </div>
                                        </div>
                                    </div>
                                }
                            })
                        } 
                </div>
            </>
        );
    }else{
        return (
            <> 
                <Logueado />
                <div className="row">
                    <div className="col-md-2 p-2">
                        <Volver />
                    </div>
                    <div className="col-md-10 p-10">
                    </div>
                    <div className="col-md-10 p-10">
                    </div> 
                    <div className="col-md-2 p-2">
                        <Button variant="success" href={"/agregarParto/" + gestacion._id}><b>Agregar Parto</b></Button>
                    </div>

    
                        <div className="col-md-12 p-12">
                            <h1 className="textBlanco">No hay un parto agregado</h1>
                        </div>
                </div>
            </>
        );
    }
}
