import React, { Component } from 'react'
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import Button from 'react-bootstrap/Button';
import axios from 'axios'
import theToken from '../Token';
import es from 'date-fns/locale/es';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';
registerLocale('es', es)

export default class CreateTratamientoPatologia extends Component {
    state = {
        tipoTratamientoSelected: 'Curativo',
        tiposTratamientos: ['Curativo', 'Paliativo'],
        tipoTratamiento: '',
        descripTratamiento: '',
        fechaTratamiento: new Date(),
        editing: false,
        _id: '',
        patologia: ''
    }

    async componentDidMount() {
        const patologiaId = this.props.match.params.patologiaId;
        const estableId = this.props.match.params.estableId;
        const nombreEstable = this.props.match.params.nombreEstable;
        const ovinoId = this.props.match.params.ovinoId;
        const nombreOvino = this.props.match.params.nombreOvino;

        if (patologiaId) {
            this.setState({
                patologia: patologiaId,
                establecimiento: estableId,
                nombreEstable: nombreEstable,
                ovino: ovinoId,
                nombreOvino: nombreOvino
            })
        }
        if (patologiaId) {
            const res = await axios.get('/api/tratamientos/' + this.props.match.params.tratamientoId, theToken());
            this.setState({
                tipoTratamientoSelected: res.data.tipoTratamiento,
                tipoTratamiento: res.data.tipoTratamiento,
                descripTratamiento: res.data.descripTratamiento,
                fechaTratamiento: new Date(res.data.fechaTratamiento),
                patologia: res.data.patologia,
                _id: res.data._id,
                editing: true
            });
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();
        if (this.state.editing) {
            const updatedTratamiento = {
                tipoTratamiento: this.state.tipoTratamientoSelected,
                descripTratamiento: this.state.descripTratamiento,
                fechaTratamiento: this.state.fechaTratamiento,
                patologia: this.state.patologia
            };
            await axios.put('/api/tratamientos/' + this.state._id, updatedTratamiento, theToken());
        } else {
            const newTratamiento = {
                tipoTratamiento: this.state.tipoTratamientoSelected,
                descripTratamiento: this.state.descripTratamiento,
                fechaTratamiento: this.state.fechaTratamiento,
                patologia: this.state.patologia,
                token: theToken()
            };
            await axios.post('/api/tratamientos', newTratamiento, theToken());
        }
        window.location.href = '/patologia/tratamientos/' + this.state.establecimiento + '/' + this.state.nombreEstable + '/' + this.state.ovino + '/' + this.state.nombreOvino + '/' + this.state.patologia;


    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeDate = fechaTratamiento => {
        this.setState({ fechaTratamiento });
    }

    render() {
        return (
        <>
            <Logueado />
            <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div>
                <div className="col-md-10 p-10">
                </div> 
                <div className="col-md-6 offset-md-3">
                    <div className="card card-body">
                        <h4>Agregar Tratamiento en el ovino "{this.state.nombreOvino}"</h4>

                        <form onSubmit={this.onSubmit}>
                            {/* SELECT TIPO DE TRATAMIENTO */}
                            <div className="form-group">
                                <br></br>
                                <h6>Seleccionar tipo de tratamiento:</h6>
                                <select
                                    className="form-control"
                                    value={this.state.tipoTratamientoSelected}
                                    onChange={this.onInputChange}
                                    name="tipoTratamientoSelected"
                                    required>
                                    {
                                        this.state.tiposTratamientos.map(tratamiento => (
                                            <option key={tratamiento} value={tratamiento}>
                                                {tratamiento}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            {/* Descripción de tratamiento */}
                            <div className="form-group">
                                <br></br>
                                <h6>Descripción del tratamiento</h6>
                                <textarea
                                    className="form-control"
                                    placeholder="Descripción del tratamiento"
                                    onChange={this.onInputChange}
                                    name="descripTratamiento"
                                    value={this.state.descripTratamiento}
                                    rows={4}
                                    cols={40}
                                />
                            </div>
                            <br></br>
                            {/* Fecha del tratamiento */}
                            <h6>Fecha del tratamiento</h6>
                            <div className="form-group">
                                <DatePicker selected={this.state.fechaTratamiento} onChange={this.onChangeDate} locale="es" className="form-control pickers" dateFormat="dd/MM/yyyy"/>
                            </div>
                            <button className="btn btn-primary">
                                Guardar <i className="material-icons">
                                    assignment
                                    </i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
