import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Button from 'react-bootstrap/Button';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import theToken from '../Token';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';

export default class createEstable extends Component {
    state = {
        nombre: '',
        email: '',
        direccion: '',
        fechaInauguracion: new Date(),
        token: '',
        editing: false,
        _id: ''
    }
    async componentDidMount() {
        if (this.props.match.params.id) {
            const res = await axios.get('/api/establecimientos/' + this.props.match.params.id, theToken());
            this.setState({
                nombre: res.data.nombre,
                email: res.data.email,
                direccion: res.data.direccion,
                fechaInauguracion: new Date(res.data.fechaInauguracion),
                //ovinoSelected: res.data.ovinos,
                _id: res.data._id,
                editing: true
            });
        }
    }
    

    onSubmit = async (e) => {
        e.preventDefault();
        if (this.state.editing) {
            const updatedEstable = {
                nombre: this.state.nombre,
                email: this.state.email,
                direccion: this.state.direccion,
                //ovinos: this.state.ovinoSelected,
                fechaInauguracion: this.state.fechaInauguracion
            };
            await axios.put('/api/establecimientos/' + this.state._id, updatedEstable, theToken());
        } else {
            const newEstable = {
                nombre: this.state.nombre,
                email: this.state.email,
                direccion: this.state.direccion,
                //ovinos: this.state.ovinoSelected,
                fechaInauguracion: this.state.fechaInauguracion,
                token: theToken()
            };
            await axios.post('/api/establecimientos', newEstable, theToken());
        }
        window.location.href = '/establecimientos';

    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeDate = fechaInauguracion => {
        this.setState({ fechaInauguracion });
    }

    render() {
        return (
            <>
                <Logueado />
                <div className="row">
                    <div className="col-md-2 p-2">
                        <Volver/>
                    </div>
                    <div className="col-md-10 p-10">
                    </div> 
                    <div className="col-md-6 offset-md-3">
                        <div className="card card-body">
                            <h4>Registrar establecimiento</h4>
                            <form onSubmit={this.onSubmit}>
                                {/* Seleccionar usuario */}
                                <div className="form-group">
                                    <p></p>
                                    {/*<p>Ovinos</p>
                                    <select
                                        className="form-control"
                                        value={this.state.ovinoSelected}
                                        onChange={this.onInputChange}
                                        name="ovinoSelected"
                                        required>
                                        {
                                            this.state.ovinos.map(ovino => (
                                                <option key={ovino} value={ovino}>
                                                    {ovino[0]}
                                                </option>
                                            ))
                                        }
                                    </select>*/}
                                </div>
                                {/* Establecimiento Nombre */}
                                <div className="form-group">
                                    <h6>Nombre del establecimiento</h6>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Nombre del establecimiento"
                                        onChange={this.onInputChange}
                                        name="nombre"
                                        value={this.state.nombre}
                                        required />
                                </div>
                                {/* Establecimiento Email */}
                                <div className="form-group">
                                    <h6>Email del establecimiento</h6>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        onChange={this.onInputChange}
                                        name="email"
                                        value={this.state.email}
                                    />
                                </div>
                                {/* Establecimiento Dirección */}
                                <div className="form-group">
                                    <h6>Dirección del establecimiento</h6>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Dirección"
                                        onChange={this.onInputChange}
                                        name="direccion"
                                        value={this.state.direccion}
                                    />
                                </div>
                                {/* Fecha de Inauguración del establecimiento */}
                                <div className="form-group">
                                    <h6>Fecha de Inauguración</h6>
                                    <DatePicker selected={this.state.fechaInauguracion} onChange={this.onChangeDate} locale="es" className="form-control pickers" dateFormat="dd/MM/yyyy"/>
                                </div>
                                <button className="btn btn-primary">
                                    Guardar
                                    <i className="material-icons">
                                        assignment
                                    </i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
