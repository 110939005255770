import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Moment from 'react-moment';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import theToken from '../Token';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';

export default function EstableOvinosList () {
    if(theToken().headers.Authorization == null){
        window.location.href = '/'
    }
    const [ovino, setOvino] = useState('');
    const [estable, setEstable] = useState('');
    const [ovinosList, setsOvinosList] = useState([]);
    const [message, setMessage] = useState('');
    const estableId = useParams().estableId;
    const nombreEstable = useParams().nombreEstable;


    useEffect(() => {
        const getEstable = async () => {
            if (estableId) {
                const resEs = await axios.get('/api/establecimientos/' + estableId, theToken())
                setEstable(resEs.data);
            }
        }
        const getOvinosEstable = async () => {
            if (estableId) {
                const resOv = await axios.get('/api/ovinos/estable/'+ estableId, theToken())
                setsOvinosList(resOv.data);
            }
        }
        getOvinosEstable();
        getEstable();
    },[estableId]);

    useEffect(() => {
        const deleteOvino = async () => {
            if (ovino) {
                const deleteOvino = {
                    activo: false
                };
                await axios.put('/api/ovinos/delete/' + ovino, deleteOvino, theToken());            
            }
        }
        deleteOvino();
    },[ovino]);

    return (
        <>
        <Logueado />
        <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div> 
                <div className="col-md-10 p-10">
                </div>
                <div className="col-md-12 p-12">
                    <h4 className="alerta">{ message }</h4>
                    <h2 className="textBlanco">Ovinos en</h2>
                    <h1 className="textBlanco">Establecimiento: { nombreEstable }</h1>
                </div>
                <div className="col-md-10 p-10">
                </div> 
                <div className="col-md-2 p-2">
                    <Button variant="success" href={"/agregarOvEs/" + estable._id + "/" + estable.nombre}><b>Agregar ovino</b></Button>
                </div>
                {
                    
                    ovinosList.map(ovino => (
                        <div className="col-md-3 p-2" key={ovino._id}>
                            <div className="card">
                                <div className="card-body">
                                    <h5>Número de caravana: {ovino.numCaravana}</h5>
                                    <p>
                                        Nombre: {ovino.nombre}
                                    </p>
                                    <p>
                                        Color de caravana: {ovino.colorCaravana}
                                    </p>
                                    <p>
                                        Tatuaje: {ovino.tatuaje}
                                    </p>
                                    <p>
                                        sexo: {ovino.sexo}
                                    </p>
                                    <p>
                                        raza: {ovino.raza}
                                    </p>
                                    <p>
                                        Fecha de nacimiento: <Moment format="DD/MM/YYYY">{ovino.nacimiento}</Moment>
                                    </p>
                                    <p>
                                        Tatuaje: {ovino.aptoReprodruccion}
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Opciones
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href={"/ovino/patologias/" + estable._id + "/" + estable.nombre + "/" + ovino._id + "/" + ovino.nombre}>Patologías</Dropdown.Item>
                                            <Dropdown.Item href={"/ovino/pesajes/" + estable._id + "/" + estable.nombre + "/" + ovino._id + "/" + ovino.nombre}>Pesajes</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Link to={"/editarOvEs/" + estable._id + "/" + estable.nombre + "/" + ovino._id} className="btn btn-primary">
                                        <i className="material-icons">
                                            border_color</i>
                                    </Link>
                                    <form /*onSubmit={this.onSubmit}*/>
                                        <button 
                                            className="btn btn-danger"
                                            onClick={() => {setOvino(ovino._id);}}
                                            >
                                            <i className="material-icons">
                                            delete</i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    ))
                }                     
            </div>
        </>
    );
}
