import React, { Component } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import Moment from 'react-moment'
import theToken from '../Token';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';

export default class AsignarPartoCordero extends Component {
    state = {
        cordero: '',
        corderosData: [],
        corderos: [],
        parto: '',
        nombreCordero: '',
        editing: false,
        _idCordero: '',
        _idParto: ''
    }

    async componentDidMount() {
        const gestacionId = this.props.match.params.gestacionId;
        const partoId = this.props.match.params.partoId;
        const resPa = await axios.get('/api/partos/gestacion/' + gestacionId, theToken())
        console.log(resPa.data);
        const resCo = await axios.get('/api/ovinos/sinagregaraparto/' + resPa.data.establecimiento + '/' + partoId, theToken());
        console.log(resCo.data);
        
        if (resCo.data.length > 0) {
            this.setState({
                corderosData: resCo.data,
                corderos: resCo.data.map(cordero => [cordero._id, cordero.nombre]),
                parto: partoId,
                gestacion: gestacionId,
                establecimiento: resPa.data.establecimiento
            })
        }        
    }

    onSubmit = async (e) => {
        e.preventDefault();
        if (this.state.cordero && this.state.cordero) {
            const addPartoCordero = {
                parto: this.state.parto,
                establecimiento: this.state.establecimiento
            };
            await axios.put('/api/ovinos/addPartoCordero/' + this.state.cordero, addPartoCordero, theToken());
            window.location.href = '/gestacion/parto/' + this.state.gestacion;
        }
    }

    onInputChange = (e) => {
        console.log(e.target.name);
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeDate = date => {
        this.setState({ date });
    }

    render() {
        return (
            <div>
                <Logueado />
                <div className="row">
                    <div className="col-md-2 p-2">
                        <Volver />
                    </div>
                    <div className="col-md-10 p-10">
                    </div> 
                    <div className="col-md-12 p-12">
                        <h1 className="textBlanco">Agregar corderos al parto</h1>
                    </div>
                    <div className="col-md-11 p-11">
                    </div> 
                    <div className="col-md-1 p-1">
                    </div> 
                    {
                        this.state.corderosData.map(cordero => { 
                            return <div className="col-md-3 p-2" key={cordero._id}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5>Número de caravana: {cordero.numCaravana}</h5>
                                        <p>
                                            Nombre: {cordero.nombre}
                                        </p>
                                        <p>
                                            Color de caravana: {cordero.colorCaravana}
                                        </p>
                                        <p>
                                            Tatuaje: {cordero.tatuaje}
                                        </p>
                                        <p>
                                            sexo: {cordero.sexo}
                                        </p>
                                        <p>
                                            raza: {cordero.raza}
                                        </p>
                                        <p>
                                            Fecha de nacimiento: <Moment format="DD/MM/YYYY">{cordero.nacimiento}</Moment>
                                        </p>
                                        <p>
                                            Apto para Reproducción: {cordero.aptoReprodruccion}
                                        </p>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between">
                                        <form onSubmit={this.onSubmit}>
                                            <button 
                                                className="btn btn-primary"
                                                onClick={() => this.setState({ cordero: cordero._id })}
                                            >
                                                Colocar
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            
                        })
                    }                
                </div>
            </div>
        )
    }
}
