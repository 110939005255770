import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Moment from 'react-moment';
import theToken from '../Token';
import { GetOvinoNumCaravana } from '../../helpers/GetOvinoNumCaravana';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';


export default function EstableServiciosList () {
    const [estable, setsEstable] = useState([]);
    const [serviciosList, setsServiciosList] = useState([]);
    const [servicio, setServicio] = useState('');
    const estableId = useParams().estableId;
    const nombreEstable = useParams().nombreEstable;

    useEffect(() => {
        const getEstable = async () => {
            if (estableId) {
                const resEs = await axios.get('/api/establecimientos/' + estableId, theToken());
                setsEstable(resEs.data);
            }
        }
        const getServiciosEstable = async () => {
            if (estableId) {
                const resSe = await axios.get('/api/servicios/estable/'+ estableId, theToken())
                setsServiciosList(resSe.data);
            }
        }
        getServiciosEstable();
        getEstable();
    },[]);

    useEffect(() => {
        const deleteServicio = async () => {
            if (servicio) {
                const deleteServicio = {
                    activo: false
                };
                await axios.put('/api/servicios/delete/' + servicio, deleteServicio, theToken());            
            }
        }
        deleteServicio();
    },[servicio]);

    return (
        <> 
            <Logueado />
            <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div>
                <div className="col-md-10 p-10">
                </div>
                <div className="col-md-12 p-12">
                    <h2 className="textBlanco">Servicios en</h2>
                    <h1 className="textBlanco">Establecimiento: { nombreEstable }</h1>
                </div>
                <div className="col-md-9 p-9">
                </div> 
                <div className="col-md-3 p-3">
                    <Button variant="success" href={"/agregarServicio/" + estable._id + "/" + estable.nombre}><b>Agregar servicio</b></Button>
                </div>
                {
                    
                    serviciosList.map(servicio => (
                        <div className="col-md-3 p-2" key={servicio._id}>                                
                            <div className="card">
                                <div className="card-body">
                                    <p>
                                        <h6>Tipo de servicio:</h6> {servicio.tipoServicio}
                                    </p>
                                    <p>
                                        <h6>N° de caravana del Carnero:</h6> {servicio.carnero === undefined ? "Agregue un ovino" : <GetOvinoNumCaravana ovinoId={servicio.carnero.id} />}
                                    </p>
                                    <p>
                                        <h6>Fecha de la servicio:</h6> <Moment format="DD/MM/YYYY">{servicio.fechaServicio}</Moment>
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Opciones
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href={"/asignarCarneroServicio/" + estableId + "/" + nombreEstable + "/" + servicio._id}>Asignar o Cambiar Carnero</Dropdown.Item>
                                            <Dropdown.Item href={"/agregarGestacion/" + servicio._id}>Agregar Gestación</Dropdown.Item>
                                            <Dropdown.Item href={"/servicio/gestaciones/" + servicio._id}>Diagnósticos de gestación</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Link to={"/editarSeOv/" + estableId + "/" + nombreEstable + "/" +  servicio._id} className="btn btn-primary">
                                        <i className="material-icons">
                                            border_color</i>
                                    </Link>
                                    <form /*onSubmit={this.onSubmit}*/>
                                        <button 
                                            className="btn btn-danger"
                                            onClick={() => {setServicio(servicio._id);}}
                                            >
                                            <i className="material-icons">
                                            delete</i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    ))
                }                
            </div>
        </>
    );
}
