import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import theToken from '../Token';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown'
import { Logueado } from '../../helpers/Logueado';

export default function EstablesList () {
    const [estables, setsEstables] = useState([]);
    const [estable, setEstable] = useState('');
    useEffect(() => {
        const token = theToken().headers.Authorization;
        const getEstablesUser = async () => {  
            const res = await axios.get('/api/establecimientos/', theToken())
            setsEstables(res.data);
        }
        getEstablesUser();
    },[]);

    useEffect(() => {
        const deleteEstable = async () => {
            if (estable) {
                const deleteEstable = {
                    activo: false 
                };
                await axios.put('/api/establecimientos/delete/' + estable, deleteEstable, theToken());            
            }
        }
        deleteEstable();
    },[estable]);
    
    return (
        <> 
            <Logueado />
            <div className="row">
                <div className="col-md-12 p-9">
                    <h1 className="textBlanco">Establecimientos</h1>
                </div>
                <div className="col-md-9 p-9">
                </div> 
                <div className="col-md-3 p-3">
                    <Button variant="success" href={"/crearEstablecimiento"}><b>Agregar establecimiento</b></Button>
                </div> 
                {
                    estables.map(estable => (
                        <div className="col-md-3 p-2" key={estable._id}>
                            <div className="card">
                                <div className="card-body">
                                    <h5>Nombre: {estable.nombre}</h5>
                                    <p>
                                        Email: {estable.email}
                                    </p>
                                    <p>
                                        Dirección: {estable.direccion}
                                    </p>
                                    {/*
                                    <p>
                                    Ovinos: {estable.ovinos}
                                    </p>
                                    <p>
                                        Total ovinos: <Badge bg="success" pill>
                                                        estable.ovinos.length
                                                    </Badge>
                                    </p>
                                    */}
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Opciones
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href={"/estable/ovinos/" + estable._id + "/" + estable.nombre}>Ovinos</Dropdown.Item>
                                            <Dropdown.Item href={"/estable/sanidades/" + estable._id + "/" + estable.nombre}>Sanidades</Dropdown.Item>
                                            <Dropdown.Item href={"/estable/servicios/" + estable._id + "/" + estable.nombre}>Servicios</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Link to={"/editarEstable/" + estable._id} className="btn btn-primary">
                                        <i className="material-icons">
                                            border_color</i>
                                    </Link>
                                    <form /*onSubmit={this.onSubmit}*/>
                                        <button 
                                            className="btn btn-danger"
                                            onClick={() => {setEstable(estable._id);}}
                                            >
                                            <i className="material-icons">
                                            delete</i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    ))
                }               
            </div>
        </>
    );
}
