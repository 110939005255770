import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Moment from 'react-moment';
import theToken from '../Token';
import { Volver } from '../../helpers/Volver';
import { GetOvinoNumCaravana } from '../../helpers/GetOvinoNumCaravana';
import { Logueado } from '../../helpers/Logueado';

export default function ServicioGestacionesList () {
    
    const [servicio, setServicio] = useState('');
    const [gestacionesList, setsGestacionesList] = useState([]);
    const [gestacion, setGestacion] = useState('');
    const servicioId = useParams().servicioId;


    useEffect(() => {
        const getGestacionesServicio = async () => {
            if (servicioId) {
                const resPr = await axios.get('/api/gestaciones/servicio/'+ servicioId, theToken())
                setsGestacionesList(resPr.data);
            }
        }
        getGestacionesServicio();
    },[]);

    useEffect(() => {
        const getServicio = async () => {
            if (servicioId) {
                const resSe = await axios.get('/api/servicios/' + servicioId, theToken());
                setServicio(resSe.data);
            }
        }
        getServicio();
    },[]);

    useEffect(() => {
        const deleteGestacion = async () => {
            if (gestacion) {
                const deleteGestacion = {
                    activo: false
                };
                await axios.put('/api/gestaciones/delete/' + gestacion, deleteGestacion, theToken());            
            }
        }
        deleteGestacion();
    },[gestacion]);

    if(gestacionesList.length > 0){
        return (
            <> 
                <Logueado />
                <div className="row">
                    <div className="col-md-2 p-2">
                        <Volver />
                    </div>
                    <div className="col-md-10 p-10">
                    </div>
                    <div className="col-md-12 p-12">
                        <h2 className="textBlanco">Gestaciones</h2>
                    </div>
                    <div className="col-md-10 p-10">
                    </div> 
                    <div className="col-md-2 p-2">
                        <Button variant="success" href={"/agregarGestacion/" + servicioId}><b>Agregar gestación</b></Button>
                    </div>
                    {
                        
                        gestacionesList.map(gestacion => (
                            <div className="col-md-3 p-2" key={gestacion._id}>
                                <div className="card">
                                    <div className="card-body">
                                        <p> 
                                            <h6>Número de caravana de oveja:</h6> {gestacion.oveja === undefined ? "Agregue un ovino" : <GetOvinoNumCaravana ovinoId={gestacion.oveja.id} />}
                                        </p>
                                        <p style={{ whiteSpace: 'pre-wrap' }}>
                                            <h6>Diagnostico de gestación:</h6> {gestacion.diagnGestacion}
                                        </p>
                                        <p>
                                            <h6>¿Preñada?:</h6> {gestacion.prenada}
                                        </p>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Opciones
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href={"/asignarOvejaGestacion/" + servicioId + "/" + gestacion._id}>Asignar o Cambiar Oveja</Dropdown.Item>
                                                <Dropdown.Item href={"/agregarParto/" + gestacion._id}>Agregar Parto</Dropdown.Item>
                                                <Dropdown.Item href={"/gestacion/parto/" + gestacion._id}>Ver Parto</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Link to={"/editarPrSe/" + servicioId +  "/" + gestacion._id} className="btn btn-primary">
                                            <i className="material-icons">
                                                border_color</i>
                                        </Link>
                                        <form /*onSubmit={this.onSubmit}*/>
                                            <button 
                                                className="btn btn-danger"
                                                onClick={() => {setGestacion(gestacion._id);}}
                                                >
                                                <i className="material-icons">
                                                delete</i>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        ))
                    }                
                </div>
            </>
        );
    } else {
        return (
            <> 
                <Logueado />
                <div className="row">
                    <div className="col-md-2 p-2">
                        <Volver />
                    </div>
                    <div className="col-md-10 p-10">
                    </div>
                    <div className="col-md-10 p-10">
                    </div> 
                    <div className="col-md-2 p-2">
                        <Button variant="success" href={"/agregarGestacion/" + servicioId}><b>Agregar gestación</b></Button>
                    </div>              
                    <div className="col-md-12 p-12">
                        <h2 className="textBlanco">No hay gestaciones agregadas</h2>
                    </div>
                </div>
            </>
        );
    }        
}
