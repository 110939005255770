import React, { Component } from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import Button from 'react-bootstrap/Button';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import theToken from '../Token';
import es from 'date-fns/locale/es';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';
registerLocale('es', es)

export default class CreatePesajeOvino extends Component {
    state = {
        nombreOvino: '',
        peso: '',
        ovino: '',
        fechaPesaje: new Date(),
        token: '',
        editing: false,
        _id: ''
    }

    async componentDidMount() {
        const ovinoId = this.props.match.params.ovinoId;
        const nombreOvino = this.props.match.params.nombreOvino;
        const estableId = this.props.match.params.estableId;
        const nombreEstable = this.props.match.params.nombreEstable;
        if (ovinoId) {
            this.setState({
                ovino: ovinoId,
                nombreOvino: nombreOvino,
                estableId: estableId,
                nombreEstable: nombreEstable
            })
        }
        if (ovinoId) {
            const res = await axios.get('/api/pesos/' + this.props.match.params.pesajeId, theToken());
            this.setState({
                peso: res.data.peso,
                ovino: res.data.ovino,
                fechaPesaje: new Date(res.data.fechaPesaje),
                _id: res.data._id,
                editing: true
            });
        }
    }



    onSubmit = async (e) => {
        e.preventDefault();
        if (this.state.editing) {
            const updatedPeso = {
                peso: this.state.peso,
                ovino: this.state.ovino,
                fechaPesaje: this.state.fechaPesaje
            };
            await axios.put('/api/pesos/' + this.state._id, updatedPeso, theToken());
        } else {
            const newPeso = {
                peso: this.state.peso,
                ovino: this.state.ovino,
                fechaPesaje: this.state.fechaPesaje,
                token: theToken()
            };
            await axios.post('/api/pesos', newPeso, theToken());
        }
        window.location.href = '/ovino/pesajes/' + this.state.estableId + "/" + this.state.nombreEstable + "/" + this.state.ovino + '/' + this.state.nombreOvino;
    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeDate = fechaPesaje => {
        this.setState({ fechaPesaje });
    }

    render() {
        return (
        <>
            <Logueado />
            <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div>
                <div className="col-md-10 p-10">
                </div>     
                <div className="col-md-6 offset-md-3">
                    <div className="card card-body">
                        <h4>Agregar Peso en Ovino "{this.state.nombreOvino}"</h4>
                        <br></br>
                        <form onSubmit={this.onSubmit}>
                            {/* Peso */}
                            <div className="form-group">
                                <h6>Peso en Kilogramos</h6>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Peso en gramos"
                                    onChange={this.onInputChange}
                                    name="peso"
                                    value={this.state.peso}
                                />
                            </div>
                            <br></br>
                            {/* Fecha de Pesaje */}
                            <div className="form-group">
                                <h6>Fecha de pesaje</h6>
                                <DatePicker selected={this.state.fechaPesaje} onChange={this.onChangeDate} locale="es" className="form-control pickers" dateFormat="dd/MM/yyyy"/>
                            </div>
                            <button className="btn btn-primary">
                                Guardar <i className="material-icons">
                                    assignment
                                    </i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
