import React, { Component } from 'react'
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import Button from 'react-bootstrap/Button';
import axios from 'axios'
import theToken from '../Token';
import es from 'date-fns/locale/es';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';
registerLocale('es', es)

export default class CreateSanidad extends Component {
    state = {
        descripSanidad: '',
        fechaSanidad: new Date(),
        establecimiento: '',
        editing: false,
        _id: ''
    }

    async componentDidMount() {
        const sanidadId = this.props.match.params.sanidadId;
        const estableId = this.props.match.params.estableId;
        const nombreEstable = this.props.match.params.nombreEstable;
        
        if (estableId) {
            this.setState({
                establecimiento: estableId,
                nombreEstable: nombreEstable
            })
        }
        if (sanidadId) {
            const res = await axios.get('/api/sanidades/' + this.props.match.params.sanidadId, theToken());
            this.setState({
                descripSanidad: res.data.descripSanidad,
                fechaSanidad: new Date(res.data.fechaSanidad),
                establecimiento: res.data.establecimiento,
                _id: res.data._id,
                editing: true
            });
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();
        if (this.state.editing) {
            const updatedSanidad = {
                descripSanidad: this.state.descripSanidad,
                fechaSanidad: this.state.fechaSanidad,
                establecimiento: this.state.establecimiento
            };
            await axios.put('/api/sanidades/' + this.state._id, updatedSanidad, theToken());
        } else {
            const newSanidad = {
                descripSanidad: this.state.descripSanidad,
                fechaSanidad: this.state.fechaSanidad,
                establecimiento: this.state.establecimiento,
                token: theToken()
            };
            await axios.post('/api/sanidades', newSanidad, theToken());
        }
        window.location.href = '/estable/sanidades/' + this.state.establecimiento + '/' + this.state.nombreEstable;


    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeDate = fechaSanidad => {
        this.setState({ fechaSanidad });
    }

    render() {
        return (
        <>
            <Logueado />
            <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div>
                <div className="col-md-10 p-10">
                </div> 
                <div className="col-md-6 offset-md-3">
                    <div className="card card-body">
                        <h4>Agregar Sanidad en establecimiento "{this.state.nombreEstable}"</h4>
                        <form onSubmit={this.onSubmit}>
                            {/* Descripción de sanidad */}
                            <div className="form-group">
                                <br></br>
                                <h6>Descripción de la sanidad</h6>
                                <textarea
                                    className="form-control"
                                    placeholder="Descripción de la sanidad"
                                    onChange={this.onInputChange}
                                    name="descripSanidad"
                                    value={this.state.descripSanidad}
                                    rows={4}
                                    cols={40}
                                />
                            </div>
                            <br></br>
                            {/* Fecha de la sanidad */}
                            <h6>Fecha de la sanidad</h6>
                            <div className="form-group">
                                <DatePicker selected={this.state.fechaSanidad} onChange={this.onChangeDate} locale="es" className="form-control pickers" dateFormat="dd/MM/yyyy"/>
                            </div>
                            <button className="btn btn-primary">
                                Guardar <i className="material-icons">
                                    assignment
                                    </i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
