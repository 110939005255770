import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Moment from 'react-moment';
import theToken from '../Token';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';

export default function OvinoPatologiasList () {
    
    const [ovino, setOvino] = useState('');
    const [patologiasList, setsPatologiasList] = useState([]);
    const [patologia, setPatolgia] = useState('');
    const ovinoId = useParams().ovinoId;
    const nombreOvino = useParams().nombreOvino;
    const estableId = useParams().estableId;
    const nombreEstable = useParams().nombreEstable;


    useEffect(() => {
        const getOvino = async () => {
            if (ovinoId) {
                const resOv = await axios.get('/api/ovinos/' + ovinoId, theToken());
                setOvino(resOv.data);
            }
        }
        const getPatologiasOvino = async () => {
            if (ovinoId) {
                const resPa = await axios.get('/api/patologias/ovino/'+ ovinoId, theToken())
                setsPatologiasList(resPa.data);
            }
        }
        getPatologiasOvino();
        getOvino();
    },[ovinoId]);

    useEffect(() => {
        const deletePatologia = async () => {
            if (patologia) {
                const deletePatologia = {
                    activo: false
                };
                await axios.put('/api/patologias/delete/' + patologia, deletePatologia, theToken());            
            }
        }
        deletePatologia();
    },[patologia]);

    return (
        <>  
            <Logueado />
            <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div>
                <div className="col-md-10 p-10">
                </div>
                <div className="col-md-12 p-12">
                    <h2 className="textBlanco">Patologías del</h2>
                    <h1 className="textBlanco">Ovino: { ovino.numCaravana }</h1>

                </div>
                <div className="col-md-10 p-10">
                </div> 
                <div className="col-md-2 p-2">
                    <Button variant="success" href={"/agregarPatologia/" + estableId + "/" + nombreEstable + "/" + ovinoId + "/" + nombreOvino}><b>Agregar patologia</b></Button>
                </div>
                {
                    
                    patologiasList.map(patologia => (
                        <div className="col-md-3 p-2" key={patologia._id}>
                            <div className="card">
                                <div className="card-body">
                                    <p>
                                        Nombre de patologia: {patologia.nomPatologia}
                                    </p>
                                    <p>
                                        Tipo: {patologia.tipoPatologia}
                                    </p>
                                    <p style={{ whiteSpace: 'pre-wrap' }}>
                                        <h6>Descripción:</h6>
                                        {patologia.descripDiagn}
                                    </p>
                                    <p>
                                        Fecha de diagnóstico: <Moment format="DD/MM/YYYY">{patologia.fechaDiagn}</Moment>
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <Button variant="success" href={"/patologia/tratamientos/" + estableId + "/" + nombreEstable + "/" + ovino._id + "/" + ovino.nombre + "/" + patologia._id}><b>Tratamientos</b></Button>
                                    <Link to={"/editarPaOv/" + estableId + "/" + nombreEstable + "/" + ovinoId + "/" + nombreOvino + "/" + patologia._id} className="btn btn-primary">
                                        <i className="material-icons">
                                            border_color</i>
                                    </Link>
                                    <form /*onSubmit={this.onSubmit}*/>
                                        <button 
                                            className="btn btn-danger"
                                            onClick={() => {setPatolgia(patologia._id);}}
                                            >
                                            <i className="material-icons">
                                            delete</i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    ))
                }                
            </div>
        </>
    );
}
