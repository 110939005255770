import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import Moment from 'react-moment'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import theToken from '../Token';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';

export default function PatologiaTratamientosList () {
    
    const [ovino, setOvino] = useState('');
    const [tratamientosList, setsTratamientosList] = useState([]);
    const [tratamiento, setTratamiento] = useState('');
    const patologiaId = useParams().patologiaId;
    const estableId = useParams().estableId;
    const nombreEstable = useParams().nombreEstable;
    const ovinoId = useParams().ovinoId;
    const nombreOvino = useParams().nombreOvino;

    useEffect(() => {
        const getOvino = async () => {
            if (ovinoId) {
                const resOv = await axios.get('/api/ovinos/' + ovinoId, theToken());
                setOvino(resOv.data);
            }
        }
        const getTratamientosOvino = async () => {
            if (patologiaId) {
                const resTra = await axios.get('/api/tratamientos/patologia/'+ patologiaId, theToken())
                setsTratamientosList(resTra.data);
            }
        }
        getTratamientosOvino();
        getOvino();
    },[patologiaId]);

    useEffect(() => {
        const deleteTratamiento = async () => {
            if (tratamiento) {
                const deleteTratamiento = {
                    activo: false
                };
                await axios.put('/api/tratamientos/delete/' + tratamiento, deleteTratamiento, theToken());            
            }
        }
        deleteTratamiento();
    },[tratamiento]);

    return (
        <> 
            <Logueado />
            <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div>
                <div className="col-md-10 p-10">
                </div>
                <div className="col-md-12 p-12">
                    <h2 className="textBlanco">Tratamientos en</h2>
                    <h1 className="textBlanco">Ovino: { ovino.numCaravana }</h1>

                </div>
                <div className="col-md-9 p-9">
                </div> 
                <div className="col-md-3 p-3">
                    <Button variant="success" href={"/agregarTratamiento/" + estableId + "/" + nombreEstable + "/" + ovinoId + "/" + nombreOvino + "/" + patologiaId}><b>Agregar tratamiento</b></Button>
                </div>
                {
                    
                    tratamientosList.map(tratamiento => (
                        <div className="col-md-3 p-2" key={tratamiento._id}>
                            <div className="card">
                                <div className="card-body">
                                    <p>
                                        <h6>Tipo de tratamiento:</h6> {tratamiento.tipoTratamiento}
                                    </p>
                                    <p>
                                        <h6>Fecha de tratamiento:</h6> <Moment format="DD/MM/YYYY">{tratamiento.fechaTratamiento}</Moment>
                                    </p>
                                    <p style={{ whiteSpace: 'pre-wrap' }}>
                                        <h6>Descripción:</h6>
                                        {tratamiento.descripTratamiento}
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <Link to={"/editarTraOv/" + estableId + "/" + nombreEstable + "/" + ovinoId + "/" + nombreOvino + "/" + patologiaId + "/" +  tratamiento._id} className="btn btn-primary">
                                        <i className="material-icons">
                                            border_color</i>
                                    </Link>
                                    <form /*onSubmit={this.onSubmit}*/>
                                        <button 
                                            className="btn btn-danger"
                                            onClick={() => {setTratamiento(tratamiento._id);}}
                                            >
                                            <i className="material-icons">
                                            delete</i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    ))
                }                
            </div>
        </>
    );
}
