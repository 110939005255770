import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import theToken from '../Token';
import es from 'date-fns/locale/es';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';
registerLocale('es', es)

export default class CreateOvino extends Component {
    state = {
        nombre: '',
        numCaravana: '',
        colorCaravana: '',
        sexoSelected: 'Hembra',
        sexo: ['Hembra', 'Macho'],
        razaSelected: '',
        razas: ['', 'CORRIEDALE', 'HAMPSHIRE DOWN', 'HAMPSHIRE DOWN P.N.O.', 'IDEAL', 'IDEAL P.N.O.', 'ILE DE FRANCE', 'ILE DE FRANCE P.N.O.', 'MERILIN', 'MERILIN P.N.O.', 'MERINO DOHNE', 'ROMNEY MARSH', 'ROMNEY MARSH P.N.O.', 'TEXEL', 'TEXEL P.N.O.'],
        cruzamientoSelected: 'PI',
        cruzamiento: ['PI', 'MO', 'CM'],
        tatuaje: '',
        nacimiento: new Date(),
        aptoReproduccionSelected: 'Si',
        aptoReproduccion: ['Si', 'No'],
        nacioSelected: 'Vivo',
        nacio: ['Vivo', 'Muerto'],
        token: '',
        editing: false,
        _id: '',
        establecimiento: '',
        nombreEstable: '',
        _idEstable: ''
    }

    async componentDidMount() {
        const estableId = this.props.match.params.estableId;
        const nombreEstable = this.props.match.params.nombreEstable;

        if (estableId) {
            this.setState({
                establecimiento: estableId,
                nombreEstable: nombreEstable
            })
        }
        if (estableId) {
            const res = await axios.get('/api/ovinos/' + this.props.match.params.ovinoId, theToken());
            this.setState({
                nombre: res.data.nombre,
                numCaravana: res.data.numCaravana,
                colorCaravana: res.data.colorCaravana,
                sexoSelected: res.data.sexo,
                razaSelected: res.data.raza,
                cruzamientoSelected: res.data.cruzamiento,
                tatuaje: res.data.tatuaje,
                nacimiento: new Date(res.data.nacimiento),
                aptoReproduccionSelected: res.data.aptoReproduccion,
                estableSelected: res.data.establecimiento,
                nacioSelected: res.data.nacio,
                _id: res.data._id,
                editing: true
            });
        }
    }



    onSubmit = async (e) => {
        e.preventDefault();
        if (this.state.editing) {
            const updatedOvino = {
                nombre: this.state.nombre,
                numCaravana: this.state.numCaravana,
                colorCaravana: this.state.colorCaravana,
                sexo: this.state.sexoSelected,
                raza: this.state.razaSelected,
                cruzamiento: this.state.cruzamientoSelected,
                tatuaje: this.state.tatuaje,
                nacimiento: this.state.nacimiento,
                aptoReproduccion: this.state.aptoReproduccionSelected,
                establecimiento: this.state.establecimiento,
                nacio: this.state.nacioSelected
            };
            await axios.put('/api/ovinos/' + this.state._id, updatedOvino, theToken());
        } else {
            const newOvino = {
                nombre: this.state.nombre,
                numCaravana: this.state.numCaravana,
                colorCaravana: this.state.colorCaravana,
                sexo: this.state.sexoSelected,
                raza: this.state.razaSelected,
                cruzamiento: this.state.cruzamientoSelected,
                tatuaje: this.state.tatuaje,
                nacimiento: this.state.nacimiento,
                aptoReproduccion: this.state.aptoReproduccionSelected,
                nacio: this.state.nacioSelected,
                establecimiento: this.state.establecimiento,
                token: theToken()
            };
            await axios.post('/api/ovinos', newOvino, theToken());
        }
        window.location.href = '/estable/ovinos/' + this.state.establecimiento + '/' + this.state.nombreEstable;

    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeDate = nacimiento => {
        this.setState({ nacimiento });
    }

    render() {
        return (
        <>
            <Logueado />
            <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div>
                <div className="col-md-10 p-10">
                </div> 
                <div className="col-md-6 offset-md-3">
                    <h2 className="textBlanco">Agregar Ovino en</h2>
                    <h1 className="textBlanco">Establecimiento: { this.state.nombreEstable }</h1> 
                </div>
                <div className="col-md-6 offset-md-3">
                    <div className="card card-body">
                        <form onSubmit={this.onSubmit}>
                            {/* Ovino nombre */}
                            <div className="form-group">
                                <h6>Nombre</h6>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nombre"
                                    onChange={this.onInputChange}
                                    name="nombre"
                                    value={this.state.nombre}
                                />
                            </div>
                            <br></br>
                            {/* Ovino numCaravana */}
                            <div className="form-group">
                                <h6>Número de caravana</h6>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Número de caravana"
                                    onChange={this.onInputChange}
                                    name="numCaravana"
                                    value={this.state.numCaravana}
                                required/>
                            </div>
                            <br></br>
                            {/* Ovino colorCaravana */}
                            <div className="form-group">
                                <h6>Color de caravana</h6>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Color de caravana"
                                    onChange={this.onInputChange}
                                    name="colorCaravana"
                                    value={this.state.colorCaravana}
                                />
                            </div>
                            {/* 
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Sexo"
                                    onChange={this.onInputChange}
                                    name="sexo"
                                    value={this.state.sexo}
                                />
                            </div>
                            */}
                            {/*Seleccionar sexo*/}
                            <br></br>
                            <div className="form-group">
                                <h6>Sexo</h6>    
                                <select
                                    className="form-control"
                                    onChange={this.onInputChange}
                                    name="sexoSelected"
                                    value={this.state.sexoSelected}
                                    >
                                    {   
                                        this.state.sexo.map(sexo => (
                                            <option key={sexo} value={sexo}>
                                                {sexo}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <br></br>
                            {/* Ovino raza*/}
                            <div className="form-group">
                                <h6>Raza</h6>
                                <select
                                    className="form-control"
                                    onChange={this.onInputChange}
                                    name="razaSelected"
                                    value={this.state.razaSelected}
                                    >
                                    {   
                                        this.state.razas.map(raza => (
                                            <option key={raza} value={raza}>
                                                {raza}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                                {/* 
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Cruzamiento (PI, MO, CM)"
                                    onChange={this.onInputChange}
                                    name="cruzamiento"
                                    value={this.state.cruzamiento}
                                />
                            </div>
                                */}
                            {/*Seleccionar cruzamiento*/}
                            <br></br>
                            <div className="form-group">
                                <h6>Cruzamiento</h6>
                                <select
                                    className="form-control"
                                    onChange={this.onInputChange}
                                    name="cruzamientoSelected"
                                    value={this.state.cruzamientoSelected}
                                    >
                                    {
                                        this.state.cruzamiento.map(cruzamiento => (
                                            <option key={cruzamiento} value={cruzamiento}>
                                                {cruzamiento}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <br></br>
                            {/* Ovino tatuaje */}
                            <div className="form-group">
                                <h6>Tatuaje</h6>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Tatuaje"
                                    onChange={this.onInputChange}
                                    name="tatuaje"
                                    value={this.state.tatuaje}
                                />
                            </div>
                            <br></br>
                                {/*
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Apto para la Reproducción"
                                    onChange={this.onInputChange}
                                    name="aptoReproduccion"
                                    value={this.state.aptoReproduccion}
                                />
                            </div>
                            */}
                            <br></br>
                            {/* Seleccionar aptoReproduccion */} 
                            <div className="form-group">
                                <h6>Apto para reproducción</h6>
                                <select
                                    className="form-control"
                                    onChange={this.onInputChange}
                                    name="aptoReproduccionSelected"
                                    value={this.state.aptoReproduccionSelected}
                                    >
                                    {
                                        this.state.aptoReproduccion.map(aptoReproduccion => (
                                            <option key={aptoReproduccion} value={aptoReproduccion}>
                                                {aptoReproduccion}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            {/*
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nacio (Vivo, Muerto)"
                                    onChange={this.onInputChange}
                                    name="nacio"
                                    value={this.state.nacio}
                                />
                            </div>
                                */}
                            {/* Seleccionar nacio */} 
                            <br></br>
                            <div className="form-group">
                                <h6>Nacio</h6>
                                <select
                                    className="form-control"
                                    value={this.state.nacioSelected}
                                    onChange={this.onInputChange}
                                    name="nacioSelected"
                                    >
                                    {
                                        this.state.nacio.map(nacio => (
                                            <option key={nacio} value={nacio}>
                                                {nacio}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            
                            {/* Ovino Nacimiento */}
                            <br></br>
                            <div className="form-group">
                                <h6>Fecha de nacimiento</h6>
                                <DatePicker selected={this.state.nacimiento} onChange={this.onChangeDate} locale="es" className="form-control pickers" dateFormat="dd/MM/yyyy"/>
                            </div>
                            <button className="btn btn-primary">
                                Guardar <i className="material-icons">
                                    assignment
                                    </i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
