import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown'
import Moment from 'react-moment'
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';
import theToken from '../Token';

export default function EstableSanidadesList () {
    const [estable, setsEstable] = useState([]);
    const [sanidadesList, setsSanidadesList] = useState([]);
    const [sanidad, setSanidad] = useState('');
    const estableId = useParams().estableId;
    const nombreEstable = useParams().nombreEstable;

    useEffect(() => {
        console.log(theToken());
        const getEstable = async () => {
            if (estableId) {
                const resEs = await axios.get('/api/establecimientos/' + estableId, theToken());
                setsEstable(resEs.data);
            }
        }
        const getSanidadesEstable = async () => {
            if (estableId) {
                const resSa = await axios.get('/api/sanidades/estable/'+ estableId, theToken())
                setsSanidadesList(resSa.data);
            }
        }
        getSanidadesEstable();
        getEstable();
    },[estableId]);

    useEffect(() => {
        const deleteSanidad = async () => {
            if (sanidad) {
                const deleteSanidad = {
                    activo: false
                };
                await axios.put('/api/sanidades/delete/' + sanidad, deleteSanidad, theToken());            
            }
        }
        deleteSanidad();
    },[sanidad]);
    
    return (
        <> 
            <Logueado />
            <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div>
                <div className="col-md-10 p-10">
                </div>
                <div className="col-md-12 p-12">
                    <h2 className="textBlanco">Sanidades en</h2>
                    <h1 className="textBlanco">Establecimiento: { nombreEstable }</h1>
                </div>
                <div className="col-md-9 p-9">
                </div> 
                <div className="col-md-3 p-3">
                    <Button variant="success" href={"/agregarSanidad/" + estable._id + "/" + estable.nombre}><b>Agregar sanidad</b></Button>
                </div>
                {
                    
                    sanidadesList.map(sanidad => (
                        <div className="col-md-3 p-2" key={sanidad._id}>
                            <div className="card">
                                <div className="card-body">
                                    <p style={{ whiteSpace: 'pre-wrap' }}>
                                        <h6>Descripción:</h6>
                                        {sanidad.descripSanidad}
                                    </p>
                                    <p>
                                        <h6>Fecha de la sanidad:</h6> <Moment format="DD/MM/YYYY">{sanidad.fechaSanidad}</Moment>
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Opciones
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href={"/asignarOvinosSanidad/" + estableId + "/" + nombreEstable + "/" + sanidad._id}>Asignar Ovinos</Dropdown.Item>
                                            <Dropdown.Item href={"/sanidad/ovinos/" + estableId + "/" + sanidad._id}>Ovinos en sanidad</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Link to={"/editarSaOv/" + estableId + "/" + nombreEstable + "/" +  sanidad._id} className="btn btn-primary">
                                        <i className="material-icons">
                                            border_color</i>
                                    </Link>
                                    <form /*onSubmit={this.onSubmit}*/>
                                        <button 
                                            className="btn btn-danger"
                                            onClick={() => {setSanidad(sanidad._id);}}
                                            >
                                            <i className="material-icons">
                                            delete</i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    ))
                }                
            </div>
        </>
    );
}
