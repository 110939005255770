import React, { Component } from 'react'
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import theToken from '../Token';
import es from 'date-fns/locale/es';
import { Volver } from '../../helpers/Volver';
import { Logueado } from '../../helpers/Logueado';
registerLocale('es', es)

export default class CreateParto extends Component {
    state = {
        tiposParto: ['Normal', 'Asistido', 'Cesárea'],
        tipoPartoSelected: 'Normal',
        tipoParto: '',
        observacion: '',
        fechaParto: new Date(),
        editing: false,
        _id: ''
    }

    async componentDidMount() {
        const partoId = this.props.match.params.partoId;
        const gestacionId = this.props.match.params.gestacionId;
        
        if (gestacionId) {
            const resPr = await axios.get('/api/gestaciones/' + gestacionId, theToken());
            this.setState({
                establecimiento: resPr.data.establecimiento,
                gestacion: gestacionId
            })
        }
        if (partoId) {
            const resPa = await axios.get('/api/partos/' + partoId, theToken());
            this.setState({
                tipoPartoSelected: resPa.data.tipoParto,
                observacion: resPa.data.observacion,
                gestacion: resPa.data.gestacion,
                fechaParto: new Date(resPa.data.fechaParto),
                establecimiento: this.state.establecimiento,
                _id: resPa.data._id,
                editing: true
            });
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();
        if (this.state.editing) {
            const updatedParto = {
                observacion: this.state.observacion,
                tipoParto: this.state.tipoPartoSelected,
                gestacion: this.state.gestacion,
                fechaParto: this.state.fechaParto,
                establecimiento: this.state.establecimiento
            };
            await axios.put('/api/partos/' + this.state._id, updatedParto, theToken());
        } else {
            const newParto = {
                observacion: this.state.observacion,
                tipoParto: this.state.tipoPartoSelected,
                gestacion: this.state.gestacion,
                fechaParto: this.state.fechaParto,
                establecimiento: this.state.establecimiento,
                token: theToken()
            };
            await axios.post('/api/partos', newParto, theToken());
        }
        window.location.href = '/gestacion/parto/' + this.state.gestacion;

    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeDate = fechaParto => {
        this.setState({ fechaParto });
    }


    render() {
        return (
        <>
            <Logueado />
            <div className="row">
                <div className="col-md-2 p-2">
                    <Volver />
                </div>
                <div className="col-md-10 p-10">
                </div> 
                <div className="col-md-6 offset-md-3">
                    <div className="card card-body">
                        <h4>Agregar Parto a Gestación</h4>
                        <form onSubmit={this.onSubmit}>
                            {/* Observación del parto */}
                            <div className="form-group">
                                <br></br>
                                <h6>Observación</h6>
                                <textarea
                                    className="form-control"
                                    placeholder="Observación"
                                    onChange={this.onInputChange}
                                    name="observacion"
                                    value={this.state.observacion}
                                    rows={4}
                                    cols={40}
                                />
                            </div>
                            <br></br>
                            <h6>Tipo de Parto</h6>
                            <select
                                className="form-control"
                                value={this.state.tipoPartoSelected}
                                onChange={this.onInputChange}
                                name="tipoPartoSelected"
                                required>
                                {
                                    this.state.tiposParto.map(tipoParto => (
                                        <option key={tipoParto} value={tipoParto}>
                                            {tipoParto}
                                        </option>
                                    ))
                                }
                            </select>
                            {/* Fecha del parto */}
                            <h6>Fecha del parto</h6>
                            <div className="form-group">
                                <DatePicker selected={this.state.fechaParto} onChange={this.onChangeDate} locale="es" className="form-control pickers" dateFormat="dd/MM/yyyy"/>
                            </div>
                            <button className="btn btn-primary">
                                Guardar <i className="material-icons">
                                    assignment
                                    </i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
